export default defineNuxtRouteMiddleware(async (route) => {
  // !solves an edge case where route.name isn't found
  if (!route?.name) return;

  const invoiceStore = useInvoiceStore();

  const whitelistedRoutes = [
    "dashboard-invoices",
    "dashboard-settings",
    "dashboard-account",
    "print",
  ];

  await invoiceStore.fetchData();

  // if route is whitelisted, return
  // if no pending invoices to sync, return
  if (
    whitelistedRoutes.some((routeName) =>
      route.name!.toString().startsWith(routeName)
    ) ||
    invoiceStore.pendingInvoices.length === 0
  ) {
    return;
  }

  if (
    invoiceStore.lastSyncedAt !== undefined &&
    new Date(invoiceStore.lastSyncedAt) <
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  ) {
    return navigateTo({
      name: "dashboard-invoices",
      force: true,
      replace: true,
      query: { forceSync: "true" },
    });
  }
});
